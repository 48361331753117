import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import {AuthService} from "../auth/auth.service";
import { User } from "../../user/user.model";
import { Router } from '@angular/router';
import {FormControl} from "@angular/forms";
import {constants} from "../../../environments/constants";
import {ConfirmService} from "../confirm/confirm.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, OnDestroy {
  selectedLanguageFlag = "./assets/img/flags/us.png";
  logoUrl = 'assets/img/logo.png';
  hideSidebar: boolean = true;
  layoutSub: Subscription;
  user: User;
  yearFormControl: FormControl;
  currentYear: any;
  years: Number[];

  constructor(
    private layoutService: LayoutService,
    private _authService: AuthService,
    public translate: TranslateService,
    public confirmService: ConfirmService,
    private router: Router,
  ) {

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    this.user = this._authService.readUserFromLocalStorage();
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  logout() {
    const text = this.translate.instant('global.confirmLogout');
    const title = this.translate.instant('global.confirmLogoutTitle');
    this.confirmService.confirm({ title, text })
      .then(() => {
        this.logoutUser();
      }).catch(() => {
      console.log('Cancel')
    });
  }

  async logoutUser() {
    await this._authService.logOutUser();
    await this.router.navigate(["/"]);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }
}
