import {Spinner} from "ngx-spinner/lib/ngx-spinner.enum";

export const constants = {
  CINETEL_USER: 'CINETEL_USER',
  CINETEL_TOKEN: 'CINETEL_TOKEN',
  CINETEL_TOKEN_REFRESH: 'CINETEL_TOKEN_REFRESH',
  LANG_KEY: 'it',
  PROCEDURE_INTERVAL: 5000,
};

export const spinnersOptions:Spinner = {
  type: 'ball-triangle-path',
  size: 'medium',
  bdColor: 'rgba(0, 0, 0, 0.8)',
  color: '#fff',
  fullScreen: true
}

export const userRoles = [

];

export const userRoleNames = {

};

export const userRolesAliases = {

};

export const userRolesKeys = {

};
