import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'services',
    loadChildren: () => import('../../services/services.module').then(m => m.ServicesModule)
  },
  {
    path: 'service-logs',
    loadChildren: () => import('../../logs/logs.module').then(m => m.LogsModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../../users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'procedures',
    loadChildren: () => import('../../procedures/procedures.module').then(m => m.ProceduresModule)
  },
];
