import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {filter, map} from 'rxjs/operators';
import { constants } from "../environments/constants";
import { TranslateService } from "@ngx-translate/core";
import { Title } from '@angular/platform-browser';
// @ts-ignore
import packageJson from "../../package.json";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    titleSubscription: Subscription;
    translateSubscription: Subscription;

    constructor(
      private router: Router,
      private titleService: Title,
      public translate: TranslateService,
    ) {
    }

    ngOnInit() {
      this.translate.use(constants.LANG_KEY);
      this.subscription = this.router.events
          .pipe(
              filter(event => event instanceof NavigationEnd)
          )
          .subscribe(() => window.scrollTo(0, 0));
      this.titleSubscription = this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => {
            let route: ActivatedRoute = this.router.routerState.root;
            let routeTitle = '';
            while (route!.firstChild) {
              route = route.firstChild;
            }
            if (route.snapshot.data['title']) {
              routeTitle = route!.snapshot.data['title'];
            }
            return routeTitle;
          })
        )
        .subscribe((title: string) => {
          if (title) {
            this.translateSubscription = this.translate.get(title).subscribe((data:any)=> {
              this.titleService.setTitle(`Cinetel - ${packageJson.version} - ${data}`);
            });
            return;
          }
          this.titleService.setTitle(`Cinetel - ${packageJson.version}`);
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.translateSubscription) {
            this.translateSubscription.unsubscribe();
        }
        if (this.titleSubscription) {
            this.titleSubscription.unsubscribe();
        }
    }



}
