import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private router:Router
  ){}

  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): Promise<boolean> {
    if (!state.url.includes('page-not-found') && !state.url.includes('session_expired') && !state.url.includes('not-authorized') && this._authService.isLoggedIn()) {
      await this.router.navigate(["/dashboard"]);
    }
    return new Promise(res => res(true))
  }
}
