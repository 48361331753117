import { Component, OnInit} from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';

import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { User } from "../../user/user.model";
import { AuthService } from "../auth/auth.service";
// @ts-ignore
import packageJson from "../../../../package.json";
import {ALLOWED_ALL_FEATURES, featureNames} from "../../../environments/features";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit {
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/logo.png';
  user: User;
  version = packageJson.version;

  constructor(
    public translate: TranslateService,
    private _authService: AuthService,
  ) {
    this.user = this._authService.readUserFromLocalStorage();
  }

  ngOnInit() {
    this.menuItems = ROUTES;
    this._authService.userUpdated.subscribe(() => {
      this.user = this._authService.readUserFromLocalStorage();
    })
  }

  showMenuItem(menuItem) {
    if (menuItem.feature === featureNames.DASHBOARD) {
      return true;
    }
    if (this.user.features.includes(ALLOWED_ALL_FEATURES)) {
      return true;
    }
    return this.user.features.includes(menuItem.feature)
  }
}
