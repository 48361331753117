import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ALLOWED_ALL_FEATURES, featureList } from "../../../environments/features";
import { User } from "../../user/user.model";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private router:Router
  ){}

  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): Promise<boolean> {
    if (this._authService.isLoggedIn()) {
      if (this._authService.isTokenExpired()) {
        return await this._authService.refreshTokenTrigger() ? true: this.router.navigate(["/"]);
      } else {
        // Check user features
        const user: User = this._authService.readUserFromLocalStorage();
        const currentFeature = featureList.find(feature => state.url.startsWith(feature.routerPath));
        if (state.url === '/dashboard') {
          return true;
        }
        if (currentFeature) {
          if (user.features.includes(currentFeature.key) || user.features.includes(ALLOWED_ALL_FEATURES)) {
            return true;
          } else {
            return await this.router.navigate(["/not-authorized"]);
          }
        }
        return await this.router.navigate(["/not-authorized"]);
      }
    } else {
      await this.router.navigate(["/"], {queryParams:{returnUrl:state.url}});
    }
    return new Promise(res => res(true))
  }
}
